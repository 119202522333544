<template>
  <div :style="{ height: height }">
    <div v-if="busy" class="overlay">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <div class="table-container">
      <SearchableTable
        v-if="!busy && items && items.length"
        :tableId="$attrs.tableId || 'chartHistoryReport'"
        v-bind:items="items"
        v-bind:fields="fields"
        v-bind:searchEnabled="false"
        v-bind:multiColumnOrder="false"
        v-bind:pagination="pagination"
        v-bind:maxResult="items.length"
      />
      <div v-else>No data was found</div>
    </div>
  </div>
</template>

<script>
import SearchableTable from "@/components/searchable-table.vue";

const interpolate = (items, cfg) => {
  var prev = {};
  var rows = {};
  for (var i = items.length - 1; i >= 0; i--) {
    for (var k in items[i]) {
      if (k !== "timestamp") {
        var interpolation =
          (k in cfg ? cfg[k].interpolation : "") || "leave_them_empty";
        if (items[i][k] === "") {
          if (interpolation == "last_value") {
            if (k in prev) {
              items[i][k] = prev[k];
            }
          } else if (interpolation == "linear_interpolation") {
            if (i == 0 && k in prev) {
              // once it reachs the end without a valid value, just repeat the last one
              var last = cfg.utils.sprintf(cfg[k].format, prev[k]);
              if (rows[k]) {
                for (var r = 1; r <= rows[k]; r++) {
                  items[i + r][k] = last;
                }
              }
              items[i][k] = last;
            } else {
              if (k in rows) {
                rows[k] += 1;
              }
            }
          }
        } else {
          var value = items[i][k];
          if (interpolation == "last_value") {
            prev[k] = value;
          } else if (interpolation == "linear_interpolation") {
            if (k in prev && rows[k]) {
              var vend = parseFloat(value.replace(/[^,.\d]/g, ""));
              var vstart = parseFloat(prev[k].replace(/[^,.\d]/g, ""));
              var inc = (vend - vstart) / (rows[k] + 1);
              for (var r = 1; r <= rows[k]; r++) {
                items[i + r][k] = cfg.utils.sprintf(cfg[k].format, vend - inc);
                vend = vend - inc;
              }
            }
            prev[k] = value;
            rows[k] = 0;
          }
        }
      }
    }
  }
};

export default {
  name: "EquipmentHistoryReportDisplay",
  components: {
    SearchableTable
  },
  data() {
    return {
      lineNumber: false,
      fields: [],
      items: [],
      nColumns: 0,
      busy: false,
      height: 0
    };
  },
  props: {
    dataset: {
      type: Array,
      required: false,
      default: () => null
    },
    selectedData: Array,
    downloading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    dataSettings: {
      type: Array,
      required: false,
      default: () => []
    },
    missingValues: {
      type: String,
      required: false,
      default: "leave_them_empty"
    }
  },
  computed: {
    nCols() {
      return this.lineNumber ? this.nColumns + 1 : this.nColumns;
    },
    pagination() {
      return !this.downloading &&
        this.$root.config.equipment_selection &&
        "page_size" in this.$root.config.equipment_selection &&
        this.$root.config.equipment_selection.page_size
        ? true
        : false;
    },
    formatList() {
      return this.references.data_value_format_types || [];
    },
    references() {
      return (
        ("config" in this.$root &&
          "references" in this.$root.config &&
          this.$root.config.references) ||
        {}
      );
    },
    portalData() {
      return (
        this.$store.getters["user/loggedUser"]?.user_profile?.portal_data ||
        null
      );
    }
  },
  watch: {
    portalData: {
      handler() {
        this.build();
      },
      deep: true
    },
    dataset: {
      handler() {
        this.build();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    text_list(text_list_id) {
      let self = this;
      let lst = (this.references.text_lists || []).filter(function(i) {
        return i.id == text_list_id;
      });
      return (lst.length && lst[0]) || null;
    },
    defaultTextListValue() {
      if (this.text_list) {
        for (var i in this.text_list.default_item) {
          return i;
          break;
        }
      }
      return "";
    },
    expressionByDataId() {
      let result = null;
      for (var i in this.dataSettings || []) {
        if (
          this.dataSettings[i]?.chartOptions?.itemStyle?.expression &&
          this.dataSettings[i]?.chartOptions?.itemStyle?.expressionSlot?.table
        ) {
          result = result || {};
          result[this.dataSettings[i].data_id] =
            this.dataSettings[i]?.chartOptions?.itemStyle?.expression;
        }
      }
      return result;
    },
    validationsByDataId() {
      let result = null;
      for (var i in this.dataSettings || []) {
        if (this.dataSettings[i]?.chartOptions?.validation) {
          var data_id = this.dataSettings[i].data_id;
          var expression = this.dataSettings[i]?.chartOptions?.validation;
          var readValue =
            this.dataSettings[i]?.chartOptions?.validationReadValue;
          result = result || {};
          result[data_id] = {
            expression: expression,
            attribute: readValue == undefined || readValue ? "initial" : "value"
          };
        }
      }
      return result;
    },
    build() {
      if (!this.dataset || !this.dataset.length) return;
      this.busy = true;
      var expressions = this.expressionByDataId();
      var validations = this.validationsByDataId();
      var dataset =
        expressions || validations
          ? JSON.parse(JSON.stringify(this.dataset))
          : this.dataset;

      let hasVirtualDevice = false;
      let items = [];
      let fields = [
        {
          name: "timestamp",
          title: "Timestamp",
          backgroundColor: "transparent",
          ix: -1
        },
        ...this.selectedData.map((item) => {
          if (
            !hasVirtualDevice &&
            item?.data?.device?.data_collector_device_id &&
            parseInt(item.data.device.data_collector_device_id) !=
            parseInt(item.data.device.id)
          ) {
            hasVirtualDevice = true;
          }
          return {
            name: item.data.id,
            title: item.label,
            backgroundColor: item.chart.color,
            style: {
              "text-align": item.data.type == "string" ? "left" : "right"
            },
            data: item.data,
            ix: (this?.dataSettings || []).find(({ data_id }) => parseInt(data_id) == parseInt(item.data.id))?.index ?? 0
          };
        })
      ].sort((a, b) => a.ix - b.ix);
      fields.forEach((field, i) => {
        if (i > 0) {
          field._format = this.formatList.find(
            ({ id }) => id == field.data?.value_format_type?.id || ""
          );
          if (field._format) {
            if (field._format.format_mask == "text_list") {
              field._text_list = this.text_list(
                field?.data?.text_list?.id || ""
              );
              if (
                field._text_list &&
                field._text_list?.items &&
                field._text_list?.items
              ) {
                field.tooltip = "<div><b>itens</b>";
                for (var vlr in field._text_list.items) {
                  field.tooltip += `<br/>${vlr} = ${field._text_list.items[vlr]}`;
                }
                field.tooltip += "</div>";
              }
            }
          }
          if (hasVirtualDevice) {
            field.title = `${field.data.name}<br/><span style='font-size:90%;opacity:0.6;padding-right: 5px;'>${field.data?.device?.name}</span>`;
          }
        }
      });
      let uniqueDates = {};
      let cacheItem;
      var expression;
      var validation;
      dataset.forEach((data) => {
        expression = (expressions && expressions[data.data_id]) || null;
        validation = (validations && validations[data.data_id]) || null;
        if (expression) {
          data.initial = data.value;
          data.value = this.$utils.eval(expression, {
            $value: data.value
          });
        }
        if (validation) {
          if (
            !this.$utils.isTrue(validation.expression, {
              $value: data[validation.attribute]
            })
          ) {
            return;
          }
        }
        let formattedDate = this.$dt.format(data.date_time);
        uniqueDates[formattedDate] = (uniqueDates[formattedDate] || 0) + 1;
        let dataValue = data.value;
        let dataInitialValue = data.initial;
        let field = fields.find((f) => data.data_id == f?.data?.id);
        if (field && field._format) {
          if (field._format.format_mask == "text_list") {
            dataValue = this.$utils.sprintf("%d", parseFloat(dataValue));
          } else if (
            field._format.format_mask == "duration" &&
            field.data.custom_format.indexOf("%") == -1
          ) {
            dataValue = moment
              .duration(parseInt(dataValue), field.data.unity_label)
              .format(field.data.custom_format || "", { trim: false });
          } else {
            dataValue = this.$utils.sprintf(
              field.data.custom_format || field._format.format_mask,
              field.data.type == "string" ? dataValue : parseFloat(dataValue)
            );
            if (dataInitialValue !== undefined) {
              dataInitialValue = this.$utils.sprintf(
                field.data.custom_format || field._format.format_mask,
                field.data.type == "string"
                  ? dataValue
                  : parseFloat(dataInitialValue)
              );
            }
          }
        }
        var fmtValue;
        if (dataInitialValue !== undefined) {
          fmtValue = `${dataValue} (${dataInitialValue})`;
        } else {
          fmtValue = `${dataValue}`;
        }
        // console.log(`${formattedDate} ${field.name} ${fmtValue}`);
        // if it's a new unique date
        if (uniqueDates[formattedDate] == 1) {
          let item = {
            timestamp: formattedDate,
            ...this.selectedData.reduce((values, { label, data_id }) => {
              values[data_id] = data_id == data.data_id ? fmtValue : "";
              return values;
            }, {})
          };
          items.push(item);
          cacheItem = item;
        } else {
          let item;
          if (formattedDate == cacheItem.timestamp) {
            item = cacheItem;
          } else {
            item = items.find(({ timestamp }) => timestamp == formattedDate);
          }
          item[field.name] = fmtValue;
        }
      });

      // BEGIN list post processing
      if (this.selectedData.length > 1) {
        let interpolationCfg = {
          enabled: false
        };
        this.selectedData.forEach((cfg) => {
          var interpolation =
            cfg?.chartOptions?.itemStyle?.missingValues || this.missingValues;
          if (interpolation !== "leave_them_empty") {
            var field = fields.find((f) => f?.data?.id == cfg?.data_id);
            interpolationCfg.enabled = true;
            interpolationCfg[field.name] = {
              format:
                field.data.custom_format || field._format.format_mask || "",
              interpolation: interpolation
            };
          }
        });
        if (interpolationCfg.enabled) {
          interpolationCfg.utils = this.$utils;
          interpolate(items, interpolationCfg);
        }
      }
      // END list post processing
      this.fields = fields;
      this.items = items;
      this.$nextTick(() => {
        this.busy = false;
      });
    }
  },
  created() {
    this._parentRect = this.$parent.$el.getBoundingClientRect();
  },
  mounted() {
    this.height =
      this._parentRect.height -
      (this.$el.getBoundingClientRect().top - this._parentRect.top);
  }
};
</script>

<style scoped>
.sheet {
  width: 100%;
}
.sheet-general-options {
  margin-top: -3px;
}
.sheet-general-options input {
  vertical-align: top;
}
.sheet-general-options label {
  padding: 0 5px;
}
.sheet-general-options label:hover {
  cursor: pointer;
  color: #72afd2;
}
.table-container {
  overflow: auto auto;
}
</style>
