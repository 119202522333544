var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.mode == 'editor' ? '' : 'resizable'},[_c('div',{staticClass:"box",class:_vm.isEditing ? 'box-warning' : 'box-primary',style:({
      height: _vm.panelHeight + 'px'
    })},[_c('div',{staticClass:"nav-tabs-custom"},[_c('ul',{staticClass:"nav nav-tabs pull-right ui-sortable-handle"},[(_vm.toolbar.reportTab)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.downloadOnly &&
            _vm.isReady &&
            _vm.dropdownOptions &&
            _vm.dropdownOptions.length
          ),expression:"\n            !downloadOnly &&\n            isReady &&\n            dropdownOptions &&\n            dropdownOptions.length\n          "}],class:{ active: _vm.tab == 0 },style:({ opacity: _vm.isEditing ? '.4' : '1' })},[_c('a',{staticClass:"equipment-data-panel-tab",attrs:{"aria-expanded":"true","disabled":_vm.isSwitching,"title":_vm.$t('titles.view_table')},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.setTab(0)}}},[_c('i',{staticClass:"fa fa-table"})])]):_vm._e(),(_vm.toolbar.chartTab)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.downloadOnly &&
            _vm.isReady &&
            _vm.dropdownOptions &&
            _vm.dropdownOptions.length
          ),expression:"\n            !downloadOnly &&\n            isReady &&\n            dropdownOptions &&\n            dropdownOptions.length\n          "}],class:{ active: _vm.tab == 1 },style:({ opacity: _vm.isEditing ? '.4' : '1' })},[_c('a',{staticClass:"equipment-data-panel-tab",attrs:{"aria-expanded":"true","disabled":_vm.isSwitching,"title":_vm.$t('titles.view_graph')},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.setTab(1)}}},[_c('i',{staticClass:"fa fa-line-chart"})])]):_vm._e(),(!_vm.toolbar.alwaysVisible)?_c('li',[_c('a',{staticClass:"equipment-data-panel-tab",attrs:{"title":_vm.$t('show_toolbar')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.toolbarVisible = !_vm.toolbarVisible}}},[_c('i',{staticClass:"fa fa-navicon",class:{ 'text-primary': _vm.toolbarVisible }})])]):_vm._e(),_c('li',{staticClass:"pull-left header",staticStyle:{"margin-right":"0"}},[_c('i',{staticClass:"fa fa-history hidden-small",staticStyle:{"margin":"0 5px 0 10px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onUserRefresh.apply(null, arguments)}}}),_c('div',{staticClass:"hidden-small toolbar-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              (_vm.isReady || _vm.mode == 'editor') &&
              (_vm.toolbar.alwaysVisible || _vm.toolbarVisible)
            ),expression:"\n              (isReady || mode == 'editor') &&\n              (toolbar.alwaysVisible || toolbarVisible)\n            "}],staticClass:"tab-toolbar-control-group ml-5"},[(_vm.toolbar.calendarUIConfig != 'none')?_c('div',{staticClass:"tab-toolbar-control mr-5",staticStyle:{"width":"auto"}},[(_vm.showDatetimePicker)?_c('DatetimeRangePicker',{attrs:{"startRef":_vm.startRef,"endRef":_vm.endRef,"min":_vm.minRef,"uiConfig":_vm.toolbar.calendarUIConfig,"shortcuts":_vm.toolbar.calendarShortCuts,"allowFutureDate":false,"showRefreshIcon":_vm.endsNow,"fontSize":"64%"},on:{"interval-changed":_vm.onIntervalChanged,"dateRangePickerEvent":_vm.onDateRangePickerEvent}}):_vm._e()],1):_vm._e(),(_vm.canEdit && _vm.toolbar.largelongIntervalSelection)?_c('div',{staticClass:"tab-toolbar-control"},[_c('IntervalDropDown',{model:{value:(_vm.queryLimit),callback:function ($$v) {_vm.queryLimit=$$v},expression:"queryLimit"}})],1):_vm._e(),(_vm.toolbar.dataFilter && !_vm.downloadOnly)?_c('div',{staticClass:"tab-toolbar-control"},[(_vm.canEdit && _vm.dropdownOptions && _vm.dropdownOptions.length)?_c('dropdown',{attrs:{"position":"right","data":_vm.dropdownOptions,"multiple":true,"showAll":true,"title":_vm.$t('titles.filter_by_data')},on:{"apply":_vm.dropdownApply}},[_c('span',[_c('i',{staticClass:"fa fa-filter toolbar-btn-icon"})])]):_vm._e()],1):_vm._e(),(_vm.showDownloadButton && !_vm.downloadOnly)?_c('div',{staticClass:"tab-toolbar-control"},[(_vm.tableId)?_c('DownloadButton',{staticStyle:{"z-index":"10"},attrs:{"tableId":_vm.tableId,"downloading":_vm.downloading,"xls":_vm.toolbar.downloadXLS,"csv":_vm.toolbar.downloadCSV},on:{"ready":function($event){_vm.downloading = true},"done":function($event){_vm.downloading = false}}}):_vm._e()],1):_vm._e()])])])]),(_vm.isReady)?_c('div',{ref:"tabSlot",staticClass:"no-padding tab-content",class:{
        active: _vm.tab == 0,
        'tab-content-legacy': !_vm.display.render_version
      },style:({
        overflow: _vm.tab == 1 ? 'hidden' : 'inherit'
      })},[(
          _vm.dataset &&
          _vm.dataset.length &&
          _vm.historyEnabledDataList.length >=
            _vm.contract.data_history_short_max_registers
        )?[_c('div',{staticClass:"text-warning text-center"},[_c('i',{staticClass:"fa fa-warning"}),_vm._v(" "+_vm._s(_vm.$t("history_data_limit_reached"))+" ")])]:_vm._e(),(_vm.downloadOnly)?[_c('div',{staticClass:"active tab-pane download-container",staticStyle:{"margin":"10px 20px 20px 20px"}},[(_vm.downloadOnly && _vm.showDatetimePicker)?_c('HistoryDownloadContainer',{attrs:{"startDate":_vm.startDate,"endDate":_vm.endDate,"items":_vm.historyEnabledDataList,"selected":_vm.selected,"isDatetimePickerVisible":_vm.isDatetimePickerVisible,"idFieldName":"data_ids"}}):_vm._e()],1)]:[(_vm.dataset && _vm.dataset.length)?_c('div',{staticClass:"active tab-pane"},[_c('keep-alive',[(_vm.tab)?_c('EquipmentHistoryChartDisplay',{ref:"chartDisplay",attrs:{"widgetOptions":_vm.chartOptions,"dataset":_vm.chartDataSet,"display":_vm.display,"fields":_vm.fields,"equipment":_vm.equipment,"selected":_vm.selected},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('EquipmentHistoryReportDisplay',{style:({
                height: _vm.isDatetimePickerVisible ? '100px' : 'inherit',
                overflow: _vm.isDatetimePickerVisible ? 'hidden' : 'inherit'
              }),attrs:{"dataset":_vm.reportDataset,"display":_vm.display,"selectedData":_vm.selectedFields,"equipment":_vm.equipment,"selected":_vm.selected,"downloading":_vm.downloading,"dataSettings":_vm.fields,"missingValues":_vm.missingValues,"tableId":_vm.tableId},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_c('div',{staticClass:"active tab-pane"},[_c('EquipmentChartHistoryNoData')],1)]],2):_vm._e(),(_vm.busy)?_c('Spin'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }