var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('TogglePanel',{attrs:{"title":"general","persistent":"toogle_history_panel_toolbar"}},[_c('div',{staticClass:"toggle-panel-inner"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-8"},[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("default_interval"))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('hints.default_interval')}})],1),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.interval),expression:"interval"}],staticClass:"form-control text-center",attrs:{"type":"number","inc":"1","min":"1","max":"24"},domProps:{"value":(_vm.interval)},on:{"input":function($event){if($event.target.composing)return;_vm.interval=$event.target.value}}}),_c('div',{staticClass:"input-group-addon"},[_vm._v(" "+_vm._s(_vm.$tc("hour", _vm.interval > 1 ? 2 : 1))+" ")])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{staticStyle:{"white-space":"nowrap"},attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("samples_out_of_boundaries"))+" ")]),_vm._l((_vm.edgesOptions),function(item,ix){return [(item.enabled)?_c('div',{key:ix,staticClass:"clicable option",class:_vm.edges == item.value ? 'text-primary' : '',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.edges = item.value}}},[_c('i',{class:_vm.edges == item.value
                      ? 'fa fa-dot-circle-o'
                      : 'fa fa-circle-o'}),_vm._v(" "+_vm._s(_vm.$t(item.label))+" "),_c('Tooltip',{attrs:{"title":_vm.$t(item.hint)}})],1):_vm._e()]})],2)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12",staticStyle:{"z-index":"100"}},[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{staticStyle:{"white-space":"nowrap"},attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("empty_cells"))+" ")]),_vm._l((_vm.cellInterpolation),function(item,ix){return [(item.enabled)?_c('div',{key:ix * 10,staticClass:"clicable option",class:_vm.missingValues == item.value ? 'text-primary' : '',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.missingValues = item.value}}},[_c('i',{class:_vm.missingValues == item.value
                      ? 'fa fa-dot-circle-o'
                      : 'fa fa-circle-o'}),_vm._v(" "+_vm._s(_vm.$t(item.label))+" "),_c('Tooltip',{attrs:{"title":_vm.$t(item.hint)}})],1):_vm._e()]})],2)])])])]),_c('TogglePanel',{attrs:{"title":"toolbar","persistent":"toogle_history_panel_toolbar"}},[_c('div',{staticClass:"toggle-panel-inner"},[_c('div',[_c('label',{staticClass:"input-checkbox clicable",attrs:{"for":"toolbarAlwaysVisible"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.toolbarAlwaysVisible = !_vm.toolbarAlwaysVisible}}},[_c('i',{class:_vm.toolbarAlwaysVisible ? 'fa fa-check-square-o' : 'fa fa-square-o'}),_vm._v(" "+_vm._s(_vm.$t("always_visible"))+" ")])]),_vm._l((_vm.toolbarGroupList),function(group){return _c('div',{key:group},[(group)?_c('div',{staticClass:"toggle-title clicable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleToolbarGroup(group)}}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(`titles.${group}`))+" ")]),_c('span',{staticClass:"btn btn-xs"},[_c('i',{class:_vm.isToolbarGroupVisible(group)
                  ? 'fa fa-caret-square-o-up'
                  : 'fa fa-caret-square-o-down'})])]):_vm._e(),(_vm.isToolbarGroupVisible(group))?_c('div',{staticClass:"toolbar-group-container"},_vm._l((_vm.toolbarFilterByGroup(group)),function(item){return _c('div',{key:item.id,class:{ 'bg-primary': item.id == _vm.toolbarDefaultTab }},[_c('label',{staticClass:"input-checkbox clicable",style:({ color: !item.enabled ? '#888' : 'inherit' }),attrs:{"for":item.id},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleToolbarItem(item.id)}}},[_c('i',{class:item.enabled ? 'fa fa-check-square-o' : 'fa fa-square-o',staticStyle:{"min-width":"14px","font-size":"90%"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])]),(group == 'tabs' && item.id != _vm.toolbarDefaultTab)?_c('div',{staticClass:"clicable tab-selector"},[_c('span',{staticClass:"clicable-orange",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.toolbarDefaultTab = item.id}}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t("set_as_default"))+" ")])]):_vm._e()])}),0):_vm._e()])})],2)]),_c('TogglePanel',{attrs:{"title":`${_vm.$t('data_series')} / ${_vm.$t('chart')}`,"persistent":"toogle_history_panel_data_series"}},[_c('div',{staticClass:"toggle-panel-inner"},[_c('ChartGeneralForm',{model:{value:(_vm.generalChartOptions),callback:function ($$v) {_vm.generalChartOptions=$$v},expression:"generalChartOptions"}}),_c('ChartSeriesForm',{attrs:{"listName":"dataList","checkable":true,"dataListParser":_vm.dataListParser,"types":['line', 'bar'],"symbol":false},model:{value:(_vm.panelOptions),callback:function ($$v) {_vm.panelOptions=$$v},expression:"panelOptions"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }